import React, { useCallback } from 'react'
import moment from 'moment'
import cx from 'classnames'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { Poster } from './components/poster'
import { ContentGetQuery } from 'shared/models/content/get-model'
import { processContentImages } from 'shared/utils/image'
import { Flex } from 'shared/components/flex'
import { Ellipsis } from 'shared/components/ellipsis'
import { ROUTES } from 'shared/constants/routes'
import { CONTENT_TYPE_LABELS } from 'shared/constants/content'
import { Link } from 'shared/components/link'
import { ImageOrientation } from 'shared/models/image/orientation'
import { ContentType } from 'shared/models/content/content-type'

import { styles } from './ai-list.styles'
import { Actions } from './components/actions'
import { ActionsWithAll } from './components/actionsWithAll'
import { Button } from 'shared/components/button'

export type ContentListProps = AppWithStyles<typeof styles> & {
  data: Array<ContentGetQuery>
  hideAiPersonalizationMsg: boolean;
  updateDontShowAgainPersonalization: () => void;
  aiResultHasNonStreamableContent: boolean;
  showOnlyAvailableForStream: boolean;
  updateShowOnlyAvailableForStream: (value: boolean) => void;
  userPreferences: { label: string; amount: number };
  searchSummary: string;
  currentUserId: string;
}

const AIListComponent: React.FC<ContentListProps> = ({
  classes,
  data,
  hideAiPersonalizationMsg,
  updateDontShowAgainPersonalization,
  aiResultHasNonStreamableContent,
  showOnlyAvailableForStream,
  updateShowOnlyAvailableForStream,
  userPreferences,
  searchSummary,
  currentUserId,
}) => {
  const isMobile = window.innerWidth < 980;

  const renderItem = useCallback(
    (data: ContentGetQuery, index: number, array: ContentGetQuery[]) => {
      const poster = processContentImages(data?.images)?.POSTER
      const getReleaseDate = () => {
        if (!data?.releaseDate) return null

        const processedReleaseDate = moment(data?.releaseDate).year()
        const config = {
          [ContentType.sport]: data?.seasonYears,
          [ContentType.movie]: processedReleaseDate,
          [ContentType.show]: processedReleaseDate,
        }

        return config[data?.contentType]
      }

      const releaseDate = getReleaseDate()

      const posterProps = {
        id: data?.id,
        url: poster?.url.medium,
        orientation: poster?.orientation || ImageOrientation.portrait,
        classes: {
          root: classes[poster?.orientation || ImageOrientation.portrait],
        },
      }

      return (
        <Link
          href={ROUTES.public.content(encodeURIComponent(data?.id))}
          target="_blank"
          rel="noreferrer noopener"
        >
          <Flex alignItems="center" classes={{ root: classes.listItem }}>
            <Flex
              autoWidth={false}
              alignItems="center"
              justify="center"
              classes={{ root: classes.posterWrapper }}
            >
              <Poster {...posterProps} />
            </Flex>
            <div
              className={
                classes[
                  `info-${poster?.orientation || ImageOrientation.portrait}`
                ]
              }
            >
              <Ellipsis
                withTooltip={false}
                text={data.title}
                classes={{ root: classes.itemTitle }}
              />
              <Flex alignItems="center" autoWidth={false}>
                <div className={classes.itemMetadata}>
                  {CONTENT_TYPE_LABELS[data.contentType]}
                </div>
                {Boolean(releaseDate) && (
                  <div className={classes.itemMetadata}>{releaseDate}</div>
                )}
              </Flex>
              <Ellipsis
                text={data.description}
                withTooltip={false}
                classes={{ root: classes.itemDescription }}
              />
            </div>
            <Actions title={data.title} id={data.id} isMobile={isMobile} isMenuBottomPosition={array.length - index < 4} />
          </Flex>
        </Link>
      )
    },
    [classes],
  )

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div>
          <div className={classes.titleAi}>Your Skroote AI Assistant says:</div>
          <div className={classes.summary} style={{ marginBottom: 8 }}>{searchSummary}</div>
          {!hideAiPersonalizationMsg && userPreferences.label && (
            <Flex autoWidth={false} className={classes.summary} justify="space-between" alignItems="center">
              <div>Your <Link className={classes.personalizationLink} to={ROUTES.private.profile.children.lists(currentUserId)}>{userPreferences.label} content</Link> {userPreferences.amount > 1 ? 'lists were' : 'list was'} used to help personalise these recommendations.</div>
              <Button 
                className={classes.summaryBtn} 
                variant="text" 
                text="Don't show again" 
                onClick={updateDontShowAgainPersonalization} 
              />
            </Flex>
          )}
          {aiResultHasNonStreamableContent && (
            <Flex autoWidth={false} className={cx(classes.summary, classes.streamableContentMsg)} justify="space-between" alignItems="center">
              <div>
                {showOnlyAvailableForStream
                  ? 'This list only shows content that is currently available to stream.'
                  : 'This list shows all content even if it is currently unavailable to stream.'
                }
              </div>
              <Button
                className={classes.summaryBtn} 
                variant="text" 
                text={showOnlyAvailableForStream ? 'Show all content' : 'Only show available for stream'}
                onClick={() => updateShowOnlyAvailableForStream(!showOnlyAvailableForStream)} 
              />
            </Flex>
          )}
        </div>
        <div>
          {data.map((x, i) => (
            <React.Fragment key={x.id}>
              {renderItem(x, i, data)}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className={classes.footer}>
        <ActionsWithAll ids={data.map(d => d.id)} isMobile={isMobile} />
      </div>
    </div>
  )
}

export const AIList = appWithStyles(styles)(AIListComponent)
