import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'
import { appFade } from 'core/theme/utils/fade'
import { ImageOrientation } from 'shared/models/image/orientation'
import { AI_MOBILE_MODAL_FOOTER_HEIGHT, AI_MODAL_FOOTER_HEIGHT, AI_SCROLLBAR_WIDTH } from '../../search-modal.content'
import { BREAKPOINT } from 'shared/constants/theme'

const landscapePosterWidth = 80
const landscapeActionsWidth = 201
const mobileActionsWidth = 80
const portraitPosterWidth = 40
const posterMargin = 12

export function styles({ spacing, palette, breakpoints }: AppTheme) {
  return appCreateStyles({
    root: {
      height: '100%',
    },
    content: {
      overflow: 'auto',
      height: `calc(100% - ${AI_MODAL_FOOTER_HEIGHT}px)`,
      
      [breakpoints.down(BREAKPOINT.mobile)]: {
        height: `calc(100% - ${AI_MOBILE_MODAL_FOOTER_HEIGHT}px)`
      },
    },
    listItem: {
      height: 'auto',
      padding: spacing(0, 3),
      borderRadius: 12,
      margin: spacing(3, 0),
      '&:hover': {
        backgroundColor: appFade(palette.common.white, 0.05),
      },
    },
    posterWrapper: {
      width: landscapePosterWidth,
      marginRight: posterMargin,
    },
    [ImageOrientation.portrait]: {
      height: 60,
      width: portraitPosterWidth,
      cursor: 'pointer',
    },
    [ImageOrientation.landscape]: {
      height: 40,
      width: landscapePosterWidth,
      cursor: 'pointer',
    },
    [`info-${ImageOrientation.portrait}`]: {
      width: `calc(100% - ${landscapePosterWidth + posterMargin + landscapeActionsWidth}px)`,

      [breakpoints.down(BREAKPOINT.mobile)]: {
        width: `calc(100% - ${landscapePosterWidth + posterMargin + mobileActionsWidth}px)`,
      },
    },
    [`info-${ImageOrientation.landscape}`]: {
      width: `calc(100% - ${landscapePosterWidth + posterMargin + landscapeActionsWidth}px)`,

      [breakpoints.down(BREAKPOINT.mobile)]: {
        width: `calc(100% - ${landscapePosterWidth + posterMargin + mobileActionsWidth}px)`,
      },
    },
    itemTitle: {
      color: '#B2B1B2',
      fontWeight: 700,
      fontSize: 16,
      cursor: 'pointer',
    },
    itemDescription: {
      paddingRight: 16,
      fontWeight: 600,
      color: appFade(palette.grey[100], 0.4),
      fontSize: 13,
    },
    itemMetadata: {
      marginRight: spacing(2),
      color: '#B5B5B5',
      fontSize: 11,
      fontWeight: 400,
      margin: spacing(2, 0),
    },
    titleAi: {
      color: 'rgba(245, 245, 245, 0.4)',
      fontSize: 13,
      fontWeight: 700,
      marginLeft: spacing(10),
      marginBottom: spacing(1.5),
    },
    summary: {
      width: 'calc(100% - 50px)',
      marginLeft: spacing(7.5),
      fontSize: 13,
      color: 'rgba(245, 245, 245, 0.4)',
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
      padding: spacing(2),
      borderRadius: 8,

      [breakpoints.down(BREAKPOINT.mobile)]: {
        justifyContent: 'flex-end',
      },
    },
    personalizationLink: {
      color: palette.primary.dark,
      textDecoration: 'underline',
    },
    summaryBtn: {
      color: palette.primary.dark,
      height: 24,
    },
    streamableContentMsg: {
      marginTop: spacing(5),
    },
    footer: {
      borderTop: '1px solid #5A5A5A',
      height: AI_MODAL_FOOTER_HEIGHT,
      paddingRight: AI_SCROLLBAR_WIDTH,
      justifyContent: 'flex-end',
      alignContent: 'center',

      [breakpoints.down(BREAKPOINT.mobile)]: {
        height: AI_MOBILE_MODAL_FOOTER_HEIGHT,
      },
    },
  })
}
